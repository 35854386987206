<template>
  <div
    ref="navbar"
    v-click-outside="$viewport.isLessThan('lg') ? () => null : closeNav"
    class="global-nav"
    :class="[
      { 'nav-open': isMobileNavActive },
      { 'large-scale-nav': largeScale }
    ]"
  >
    <div class="global-nav-container">
      <div class="global-nav-header flex items-center z-50 bg-gray-800">
        <nuxt-link :to="{ name: 'index' }" class="logo">
          <img src="/TAFE-logo.svg" data-not-lazy alt="Go to home" />
        </nuxt-link>
        <GlobalNavMenuButton />
      </div>
      <div class="global-nav-content md:contents overscroll-none">
        <GlobalNavSearchBar v-if="$route.name !== 'course-search'" />
        <hr class="flex-break" />
        <div
          class="global-nav-list-wrapper"
          :class="{ 'active-mobile-nav': isMobileNavActive && isSmallView }"
        >
          <div class="flex-grow">
            <ul
              class="nav-lv-0 list-unstyled flex flex-col md:flex-row flex-wrap -mt-px"
            >
              <li
                v-for="(topLevel, i) in navItems"
                :key="i"
                :class="{ active: i === activeNav[0] }"
                class="md:z-60"
              >
                <GlobalNavListItem
                  :item="topLevel"
                  :index="i"
                  class="text-white"
                />
                <div
                  v-if="topLevel.children"
                  ref="megamenu"
                  class="mega-menu md:shadow"
                  :style="{ maxHeight: megaMenuHeight }"
                >
                  <GlobalNavbarList
                    :items="topLevel.children"
                    :index="1"
                    class="nav-lv-1 bg-gray-700 text-white md:w-1/3 lg:w-1/4 md:py-4"
                    :class="{ active: i === activeNav[0] }"
                    :active-child="activeThirdLevel"
                    :style="{ maxHeight: megaMenuHeight }"
                  />
                  <template v-for="(secondLevel, j) in topLevel.children">
                    <GlobalNavbarList
                      v-if="
                        secondLevel.children && secondLevel.children.length > 0
                      "
                      :key="j"
                      :items="secondLevel.children"
                      :index="2"
                      :split="$viewport.isLessThan('lg') ? 18 : 12"
                      class="text-black bg-white font-semibold md:py-4"
                      :class="{
                        active: j === activeThirdLevel,
                        'mobile-active': j === activeNav[1]
                      }"
                    />
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <div class="flex-shrink bg-brand-grey-700 text-white md:ml-auto">
            <ClientOnly>
              <ShowApplyServiceNavbar
                :shortlist-offerings="shortlistOfferings"
              />
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type GlobalNavLink = models.stores.siteConfig.GlobalNavLink
import { storeToRefs } from 'pinia'
import { useGlobalNavStore } from '~/stores/globalNav'
import { useShortlistStore } from '~/stores/shortlist'
import { useUiStore } from '~/stores/ui'
import { useSiteConfigStore } from '~/stores/siteConfig'
// import sso from '@/mixins/sso'
// import desktopHeader from '@/mixins/desktopHeader'
// import deviceScaleRatio from '@/mixins/deviceScaleRatio'

// Data
const deviceScaleRatio = useDeviceScaleRatio()
const largeScale = computed(() => {
  return deviceScaleRatio.largeScale.value
})

const navbar = ref<HTMLElement | null>(null)
const parents: Ref<number[]> = ref([])
const activePage: Ref<number[]> = ref([])
const studentHub = ref({
  href: '/student-hub',
  label: 'Student hub'
})

const megaMenuHeight = ref('100%')
const globalNavStore = useGlobalNavStore()
const siteConfigStore = useSiteConfigStore()
const shortlistStore = useShortlistStore()
const uiStore = useUiStore()
const viewport = useViewport()

const { isMobileNavActive, activeNav } = storeToRefs(globalNavStore)
const { config: siteConfig } = storeToRefs(siteConfigStore)
const { items: shortlistOfferings } = storeToRefs(shortlistStore)
const { isChatOpen } = storeToRefs(uiStore)

// Computed
const navItems = computed(() => {
  return siteConfig.value?.globalNav || []
})

const activeThirdLevel = computed(() => {
  if (activeNav.value.length > 1) {
    return activeNav.value[1]
  } else if (activePage.value[0] === activeNav.value[0]) {
    return activePage.value[1]
  } else {
    const parent = activeNav.value.length
      ? navItems.value[activeNav.value[0]].children
      : null
    return (
      parent && parent.findIndex((e) => e.children && e.children.length > 0)
    )
  }
})

const isSmallView = computed(() => {
  return viewport.isLessThan('md')
})

// Lifecycle
const route = useRoute()
watch(activeNav, () => {
  setActiveParent(navItems.value), { deep: true }
})
watch(route, (newVal: unknown) => {
  if (newVal) {
    setMobileNav(false)
    closeNav()
  }
})
onMounted(() => {
  nextTick(() => {
    setActiveParent(navItems.value)
    megaMenuHeight.value = `calc(100vh - ${navbar.value?.offsetHeight}px)`
  })
})

// Methods
const { toggleMobileNav, setMobileNav, closeNav } = globalNavStore

const setActiveParent = (items: GlobalNavLink[], level = 0) => {
  for (let index = 0; index < items.length; index++) {
    const item = items[index]
    if (item.to === route.fullPath || item.to?.name === route.name) {
      parents.value[level] = index
      activePage.value = [parents.value[0], parents.value[1]]
      break
    }
    parents.value[level] = index
    if (item.children && item.children.length) {
      setActiveParent(item.children, level + 1)
    }
  }
}
</script>

<style lang="postcss">
/* ToDo: as a separate ticket
  while we need to maintain visual parity between the E2E global navigation and Liferay global navigation we
  need to make use of a lot of custom css classes, once we no longer have need for the Liferay global
  navigation parity, we can remove as many custom classes as possible and move the Tailwind CSS classes
  into the template
*/

.dropdown-menu-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
}

.dropdown-menu {
  @apply bg-gray-700 right-0 flex flex-col items-start justify-end p-4 pt-20;
  width: 272px;
}

.logged-in-btn svg {
  height: 0.5em !important;
}

.global-nav {
  @apply text-white w-full z-40;
}

.global-nav .logo > img {
  height: 38px;
}

.global-nav-container {
  @apply flex w-full flex-1 bg-brand-grey-700 relative;
}

.global-nav-list-wrapper {
  @apply flex flex-col flex-grow mt-auto bg-brand-grey-700 relative;
}

.global-nav-list-wrapper.active-mobile-nav {
  @apply overflow-x-hidden	overflow-y-scroll;
}

.global-nav-tool {
  @apply p-4 flex flex-row items-center;
}

.global-nav-tool .tf-icon {
  @apply text-2xl;
}

.global-nav-pill {
  @apply rounded-full w-6 h-6 leading-6 text-center;
  background: #e5143c;
}

.global-nav .nav-item {
  text-decoration: none;
}

@media (max-width: 376px) {
  .nav-lv-2 > ul {
    height: calc(128px + 100vh);
  }
}

@media (max-width: 767px) {
  .global-nav {
    @apply top-0 right-0 left-0 overflow-x-hidden;
  }

  .global-nav.nav-open {
    @apply fixed bottom-0 overflow-y-auto overflow-x-hidden;
  }

  .global-nav button {
    @apply outline-none;
  }

  .global-nav .logo > img {
    height: 24px;
  }

  .global-nav-container {
    @apply flex-col h-full;
  }

  .global-nav-header .logo {
    @apply block px-4 py-3;
  }

  .flex-break {
    @apply hidden;
  }

  .global-nav .global-nav-content {
    display: flex;
    flex-direction: column;
    height: 0;
    /* this needs to be calculated with JS */
    transform: translateY(-140vh);
    transition: transform 0.6s ease;
  }

  .global-nav.nav-open .global-nav-content {
    height: 100%;
    transform: translateY(0);
  }

  /* Slide in nav levels */
  .nav-lv-1,
  .nav-lv-2 {
    @apply absolute inset-0 overflow-y-auto z-50 transition-all duration-700;
    transform: translateX(100%);
  }
  .nav-lv-1 > ul {
    background: #4d4d4d;
  }
  .nav-lv-2 {
    background: #f4f4f5;
    color: #000;
  }
  .nav-lv-2 > ul {
    background: #f4f4f5;
    &:last-child {
      padding-bottom: 15px;
    }
  }
  .nav-lv-0 > li.active .nav-lv-1.active,
  .nav-lv-0 > li.active .nav-lv-2.mobile-active,
  .log-in-nav.active {
    @apply overflow-visible;
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  .global-nav-container {
    @apply flex flex-row flex-wrap relative mx-auto px-6 max-w-screen-2xl;
  }

  .global-nav-header .logo {
    @apply block py-4;
  }

  .flex-break {
    @apply w-full border-gray-600;
    z-index: 70;
  }

  .global-nav-list-wrapper {
    @apply flex flex-row flex-wrap -mx-3;
  }
  .global-nav-tool {
    @apply px-3;
  }
  .global-nav-tool:hover,
  .global-nav-tool:focus {
    @apply bg-brand-grey-600;
  }

  .global-nav-pill {
    @apply ml-2;
  }

  .nav-lv-0 > li.active > .nav-item {
    @apply bg-gray-700;
  }

  .mega-menu {
    @apply hidden;
    background: #4d4d4d;
    min-height: 150px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    /*overflow-y: auto;*/
  }

  li.active .mega-menu {
    @apply flex;
  }

  .nav-lv-1,
  .nav-lv-2 {
    @apply overflow-y-auto;
  }

  .nav-lv-2 {
    @apply hidden;
  }
  .nav-lv-2.active {
    @apply flex flex-1;
  }
  .nav-lv-2.active > ul {
    flex: 0 1 50%;
  }
}

.text-sm {
  font-size: 0.875rem;
}

@media (min-width: 1280px) {
  .global-nav-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .nav-lv-2.active > ul {
    flex-basis: 33%;
  }
}

.large-scale-nav {
  position: relative !important;
}
</style>
