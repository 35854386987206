<template>
  <button
    v-if="item.children && item.children.length"
    class="nav-item w-full text-left"
    :class="listItemClass"
    type="button"
    @click.stop="toggleNav(item.level, index)"
  >
    <span class="flex-1">{{ item.title }}</span>
    <icon
      name="IconChevronRight"
      class="text-base"
      :class="item.level === 0 ? 'md:hidden' : ''"
    />
  </button>

  <TafeLink
    v-else
    :item="item"
    :class="['nav-item ', brandColor, listItemClass]"
    no-underline
    @click="toggleNav(item.level, index), closeNav()"
  />
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useGlobalNavStore } from '~/stores/globalNav'

export default {
  name: 'GlobalNavListItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(useGlobalNavStore, ['isNavActive']),
    listItemClass() {
      return `lv-${this.item.level}`
    },
    brandColor() {
      return (
        (this.item.color && `hover:bg-brand-${this.item.color}-300`) ||
        'hover:bg-gray-300 hover:text-black'
      )
    },
    iconName() {
      return this.item.external ? 'IconNewWindow' : 'IconChevronRight'
    }
  },
  methods: {
    ...mapActions(useGlobalNavStore, ['openNav', 'closeNav']),
    toggleNav(level, index, closeNav = false) {
      if (level === 0) {
        const { gtag } = useGtag()

        gtag('event', 'topNavigationLinks', {
          navigationLink: this.item.title
        })

        this.isNavActive(level, index)
          ? this.closeNav()
          : this.openNav({ level, index })
      } else {
        this.openNav({ level, index })
      }

      if (closeNav) this.closeNav()
    }
  }
}
</script>

<style lang="postcss">
/* ToDo: as a separate ticket
  while we need to maintain visual parity between the E2E global navigation and Liferay global navigation we
  need to make use of a lot of custom css classes, once we no longer have need for the Liferay global
  navigation parity, we can remove as many custom classes as possible and move the Tailwind CSS classes
  into the template
*/

.nav-item {
  @apply flex flex-row items-center px-4 py-3 cursor-pointer;
}

.nav-item.lv-0 {
  @apply py-4;
}
.nav-item.lv-0:hover,
.nav-item.lv-0.active {
  @apply bg-gray-700 underline;
}

@media (min-width: 768px) {
  .nav-item {
    @apply py-2;
  }
  .nav-item.lv-0 {
    @apply px-3;
  }
  .nav-item.lv-2 {
    @apply py-1;
  }
  .nav-lv-1 > ul > li > .nav-item:hover,
  .nav-lv-1 > ul > .active > .nav-item {
    @apply bg-white text-black;
  }
}
</style>
